import React from 'react';
import { useTranslation } from "react-i18next";

const About = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <section id="about" className="about">
                <div className="container">
                    <div className="section-title">
                        <h2>{t("about.Title")}</h2>
                        <p>{t("about.Line1")},<br /><br />

                            {t("about.Line2")} <br />

                            {t("about.Line3")} <a target={'_blank'} href="https://www.youtube.com/@asianinterstellar">{t("header.Title")}</a> {t("about.Line4")} <br /><br />

                            {t("about.Line5")} <br />

                            {t("about.Line6")} - <a target={'_blank'} href="https://www.patreon.com/asianinterstellar">Patreon</a> {t("about.Line7")}</p>
                    </div>

                </div>
            </section>
        </>
    );
}

export default About;
