import React from 'react';
import Footer from '../footer/footer';
import Header from '../header/header';
import Main from '../main/main';

const Home = () => {
    return (
        <>
            <Header />
            <Main />
            <Footer />
        </>
    );
}

export default Home;
