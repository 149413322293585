import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

const languages = [
    { value: 'ja', text: "Japanese (日本語)" },
    { value: 'en', text: "English (英語）" },
]


const Header = () => {
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState();
    const handleChange = e => {
        setLang(e.target.value);
        let loc = "https://asianinterstellar.com/";
        window.location.replace(loc + "?lng=" + e.target.value);
    }

    const form = useRef();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_bxxqhn9', 'template_1djr5eo', form.current, 'ha9rBn2dpoDqQ4-Vn')
            .then((result) => {
                console.log(result.text);
                setIsLoad(true)
                setTimeout(() => {
                    setIsLoad(false);
                    setIsSuccess(true);
                }, 2000);
            }, (error) => {
                console.log(error.text);
                setIsLoad(true)
                setTimeout(() => {
                    setIsLoad(false);
                    setIsFailed(true);
                }, 2000);
            });
        e.target.reset();
    };

    useEffect(() => {
        const countdownEl = document.querySelector('.countdown');
        if (countdownEl) {
            const countDownDate = new Date(countdownEl.getAttribute('data-count')).getTime();

            const x = setInterval(function () {
                const now = new Date().getTime();
                const distance = countDownDate - now;

                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                countdownEl.innerHTML = `
              <div>
                <h3>${days}</h3>
                <h4>${t("timer.Days")}</h4>
              </div>
              <div>
                <h3>${hours}</h3>
                <h4>${t("timer.Hours")}</h4>
              </div>
              <div>
                <h3>${minutes}</h3>
                <h4>${t("timer.Minutes")}</h4>
              </div>
              <div>
                <h3>${seconds}</h3>
                <h4>${t("timer.Seconds")}</h4>
              </div>
            `;

                if (distance < 0) {
                    clearInterval(x);
                    countdownEl.innerHTML = "EXPIRED";
                }
            }, 1000);
        }
    }, []);


    return (
        <>
            <select value={lang} defaultValue={i18n.language} onChange={handleChange} tabIndex={1} className="form-select lang">
                {languages.map(item => {
                    return (<option key={item.value}
                        value={item.value}>{item.text}</option>);
                })}
            </select>
            <header id="header" className="d-flex align-items-center">
                <div className="container d-flex flex-column align-items-center">
                    <h1>{t("header.Title")}</h1>
                    <h2>{t("header.Note")}</h2>
                    <div className="countdown d-flex justify-content-center" data-count="2023/5/1">
                        <div>
                            <h3>%d</h3>
                            <h4>{t("timer.Days")}</h4>
                        </div>
                        <div>
                            <h3>%h</h3>
                            <h4>{t("timer.Hours")}</h4>
                        </div>
                        <div>
                            <h3>%m</h3>
                            <h4>{t("timer.Minutes")}</h4>
                        </div>
                        <div>
                            <h3>%s</h3>
                            <h4>{t("timer.Seconds")}</h4>
                        </div>
                    </div>
                    <div className="subscribe">
                        <h4>{t("subs.Subscribe")}</h4>
                        <form ref={form} onSubmit={sendEmail} role="form" className="php-email-form">
                            <div className="subscribe-form">
                                <input type="email" name="email" required /><input type="submit" value={t("subs.Button")} />

                            </div>
                            <div className="mt-2">
                                {isLoad && <div className="loading">Loading</div>}
                                {isFailed && <div className="error-message">{t("subs.Error")}</div>}
                                {isSuccess && <div className="sent-message">{t("subs.Success")}</div>}
                            </div>
                        </form>
                    </div>
                    <div className="social-links text-center">
                        <a href="https://www.instagram.com/asianinterstellar/" target={'_blank'} className="instagram"><i className="bi bi-instagram"></i></a>
                        <a href="https://www.youtube.com/@asianinterstellar" target={'_blank'} className="youtube"><i className="bi bi-youtube"></i></a>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
