import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

const Contact = () => {
    const { t, i18n } = useTranslation();

    const form = useRef();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_bxxqhn9', 'template_vffijvt', form.current, 'ha9rBn2dpoDqQ4-Vn')
            .then((result) => {
                console.log(result.text);
                setIsLoad(true)
                setTimeout(() => {
                    setIsLoad(false);
                    setIsSuccess(true);
                }, 2000);
            }, (error) => {
                console.log(error.text);
                setIsLoad(true)
                setTimeout(() => {
                    setIsLoad(false);
                    setIsFailed(true);
                }, 2000);
            });
        e.target.reset();
    };

    return (
        <>
            <section id="contact" className="contact">
                <div className="container">

                    <div className="section-title">
                        <h2>{t("contact.Title")}</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className="info">
                                <div className="address">
                                    <i className="bi bi-geo-alt"></i>
                                    <h4>{t("location.Title")}:</h4>
                                    <p>{t("location.Location")}</p>
                                </div>

                                <div className="email">
                                    <i className="bi bi-envelope"></i>
                                    <h4>{t("email.Tilte")}:</h4>
                                    <p>info@asianinterstellar.com</p>
                                </div>

                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d207446.97304963655!2d139.60078234926215!3d35.668162527194426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b857628235d%3A0xcdd8aef709a2b520!2sTokyo!5e0!3m2!1sen!2sjp!4v1676966074181!5m2!1sen!2sjp" frameBorder="0" loading="lazy" style={{ border: "0", width: "100%", height: "290px" }} allowFullScreen=""></iframe>
                            </div>

                        </div>
                        <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                            <form ref={form} onSubmit={sendEmail} role="form" className="php-email-form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">{t("contact.Name")}</label>
                                        <input type="text" name="user_name" className="form-control" id="name" required />
                                    </div>
                                    <div className="form-group col-md-6 mt-3 mt-md-0">
                                        <label htmlFor="name">{t("contact.Email")}</label>
                                        <input type="email" className="form-control" name="user_email" id="email" required />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="name">{t("contact.Subject")}</label>
                                    <input type="text" className="form-control" name="subject" id="subject" required />
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="name">{t("contact.Message")}</label>
                                    <textarea className="form-control" name="message" rows="10" cols="30" required></textarea>
                                </div>
                                <br />
                                <div className="text-center"><button type="submit">{t("contact.Button")}</button></div>
                                <div className="my-3">
                                    {isLoad && <div className="loading">Loading</div>}
                                    {isFailed && <div className="error-message">{t("subs.Error")}</div>}
                                    {isSuccess && <div className="sent-message">{t("contact.Success")}</div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
