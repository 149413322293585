import React from 'react';

const Footer = () => {
    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Asian Interstellar</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">Designed & Developed by <a href="https://www.asianinterstellar.com/">Asian Interstellar</a>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
