import React from 'react';
import About from '../about/about';
import Contact from '../contact/contact';

const Main = () => {
    return (
        <>
            <main id="main">
                <About />
                <Contact />
            </main>
        </>
    );
}

export default Main;
